import React from "react";
import PopupBlock from "../PopupBlock";
import SentEmailSuccessBody from "./SentEmailSuccessBody";

const SentEmailSuccessComponent = () => (
  <div className="password-form">
    <PopupBlock
      width="xxl"
      height="auto"
      BodyRender={() => (
        <SentEmailSuccessBody />
      )}
      FooterRender={() => (<div />)}
    />
  </div>
);

export default SentEmailSuccessComponent;
