import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import queryString from "query-string";

import PasswordForget from "./containers/PasswordForget";
import PasswordReset from "./containers/PasswordReset";
import SentEmailSuccess from "./components/SentEmailSuccess";
import ResetPwdSuccess from "./components/ResetPwdSuccess";
import { VerifyEmailSuccess } from "./components/VerifyEmailSuccess";

// import Home from "./components/Home";
import "./App.scss";

class App extends Component {
  constructor(props) {
    super(props);

    const { history, location: { search } } = props;
    const queries = queryString.parse(search);
    const { mode } = queries;

    this.state = {
      queries: queries
    };

    switch (mode) {
      case 'resetPassword':
        history.push("/pwd-reset");
        break;
      case 'recoverEmail':
        // Display email recovery handler and UI.
        // NOT HANDLED ATM
        break;
      case 'verifyEmail':
        history.push("/verify-email");
        break;
      default:
        // Error: invalid mode.
    }
  }

  render() {
    const {queries} = this.state;

    return (
      <Switch>
        <Route
          exact path="/pwd-reset"
          render={(props) => <PasswordReset {...props}  queries={queries }/>}
        />
        <Route exact path="/sent-email" component={SentEmailSuccess} />
        <Route exact path="/reset-success" component={ResetPwdSuccess} />
        <Route exact path="/pwd-forgot" component={PasswordForget} />
        <Route
          exact path="/verify-email"
          render={(props) => <VerifyEmailSuccess {...props}  queries={queries } />}
        />

        <Route exact path="/" component={PasswordForget} />
        {/* <Route path="/" component={Home} /> */}
        {/* <Redirect to="/pwd-forgot" /> */}
      </Switch>
    );
  }
}

export default withRouter(App);
