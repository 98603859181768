import React from "react";
import PopupBlock from "../PopupBlock";
import ResetPwdSuccessBody from "./ResetPwdSuccessBody";

const ResetPwdSuccessComponent = () => (
  <div className="password-form">
    <PopupBlock
      width="xxl"
      height="auto"
      BodyRender={() => (
        <ResetPwdSuccessBody />
      )}
      FooterRender={() => (<div />)}
    />
  </div>
);

export default ResetPwdSuccessComponent;
