import React from "react";

const PwdRequirements = () => (
  <div className="password-requirements-wrapper">
    Password requirements:
    <div className="req-list">
      <div className="req-item">&bull; Minimum 6 characters</div>
      {/* <div className="req-item">&bull; Combination of upper and lower case</div> */}
    </div>
  </div>
);

export default PwdRequirements;
