import React from "react";
// import queryString from "query-string";

import PopupBlock from "../../components/PopupBlock";
import PasswordResetBody from "../../components/PasswordReset/PasswordResetBody";
import PasswordResetFooter from "../../components/PasswordReset/PasswordResetFooter";
import PwdRequirements from "../../components/PasswordReset/PwdRequirements";
import firebase from "../../utils/firebase";

export default class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newPwd: '',
      confirmPwd: '',
      error: ''
    };

    console.log("CONSTRUCTOR PROPS: ", props);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeInput(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      error: ''
    });
  }

  onSubmit() {
    const { history, queries } = this.props;
    const { newPwd, confirmPwd } = this.state;

    if (!newPwd || !confirmPwd) {
      this.setState({
        error: "Please input passwords."
      });

      return false;
    }

    if (newPwd !== confirmPwd) {
      this.setState({
        error: "Passwords do not match."
      });

      return false;
    }

    if (newPwd.length < 6) {
      this.setState({
        error: <PwdRequirements />
      });

      return false;
    }

    // console.log("Location.search: ", search, queries, newPwd);
    // console.log("Location.search: ", queries, newPwd);
    firebase
      .auth()
      .confirmPasswordReset(queries.oobCode, newPwd)
      .then(res => {
        // console.log("Password reset! ", res);
        history.push("/reset-success");
      })
      .catch(err => {
        // console.log("Error resetting password: ", err);
        if (err.code === "auth/expired-action-code") {
          this.setState({
            error: "Password reset code has expired."
          });
        } else if(err.code === "auth/invalid-action-code") {
          this.setState({
            error: "Password reset code is invalid."
          })
        } else {
          this.setState({
            error: "Failed to reset password, please try again."
          });
        }
      })
    return true;
  }

  render() {
    const { newPwd, confirmPwd, error } = this.state;

    return (
      <div className="password-form">
      <PopupBlock
        width="xxl"
        height="auto"
        BodyRender={() => (
          <PasswordResetBody
            newPwd={newPwd}
            confirmPwd={confirmPwd}
            error={error}
            onChangeInput={this.onChangeInput}
          />
        )}
        FooterRender={() => (
          <PasswordResetFooter
            onClickOK={this.onSubmit}
          />
        )}
      />
    </div>
    )
  }
}
