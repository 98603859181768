import React from "react";

import PopupContentBox from "../../components/PopupContentBox";
import KovaaksLogo from '../../assets/images/logo.svg'

export const VerifyEmailSuccessBody = () => (
  <div className="password-form-inner">
    <div className="password-form-header">
      <div className="header-content-wrapper">
        <div className="header-title">
          <span>Email Verified</span>
        </div>
        <div className="header-subtitle">
          <span>Thank you!</span>
        </div>
      </div>
      <div className="header-logo-wrapper">
        <img src={KovaaksLogo} alt="" className="header-logo" />
      </div>
    </div>
    <div className="password-form-content">
      <PopupContentBox>
        <div className="password-form-content-main">
          <div className="password-form-text">
            You're good to go! GLHF!
          </div>
        </div>
      </PopupContentBox>
    </div>
  </div>
);



export const VerifyEmailErrorBody = () => (
  <div className="password-form-inner">
    <div className="password-form-header">
      <div className="header-content-wrapper">
        <div className="header-title">
          <span>Email Verification</span>
        </div>
        <div className="header-subtitle">
          <span>Please try again.</span>
        </div>
      </div>
      <div className="header-logo-wrapper">
        <img src={KovaaksLogo} alt="" className="header-logo" />
      </div>    </div>
    <div className="password-form-content">
      <PopupContentBox>
        <div className="password-form-content-main">
          <div className="password-form-text">
            There was a problem.
          </div>
        </div>
      </PopupContentBox>
    </div>
  </div>
)