import React, { useState, useEffect } from "react";
import PopupBlock from "../PopupBlock";
import { VerifyEmailSuccessBody, VerifyEmailErrorBody } from "./VerifyEmailSuccessBody";
import firebase from "../../utils/firebase";

const renderLoading = () => (<div className="password-form-inner">Loading...</div>)
const renderError = () => (<VerifyEmailErrorBody />)
const renderSuccess = () => (<VerifyEmailSuccessBody />)

export const VerifyEmailSuccess = props => {
  const { queries } = props
  const [verifySuccess, setVerifySuccess] = useState(null) // true for success, false for error, null when loading

  let VerifyMessageComponent

  if (verifySuccess === false) {
    VerifyMessageComponent = renderError
  } else if (verifySuccess === true) {
    VerifyMessageComponent = renderSuccess
  } else if (verifySuccess === null) {
    VerifyMessageComponent = renderLoading
  }

  useEffect(() => {
    try {
      firebase
      .auth()
      .applyActionCode(queries.oobCode)
      .then(() => {
        setVerifySuccess(true)
      })
      .catch(err => {
        setVerifySuccess(false)
      })
    } catch (e) {
      setVerifySuccess(false)
    }
  }, [queries.oobCode])

  return (
    <div className="password-form">
      <PopupBlock
        width="xxl"
        height="auto"
        BodyRender={VerifyMessageComponent}
        FooterRender={() => (<div />)}
      />
    </div>
  )
}

