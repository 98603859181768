import axiosModule from 'axios';

const safeJsonParse = (string) => {
  try {
    return JSON.parse(string);
  } catch (error) {
    return null;
  }
};

const isObject = (value) => value != null && (typeof value === 'object' || typeof value === 'function');

const contentTypes = {
  json: { 'Content-Type': 'application/json' }
};

export const axios = {
  /**
   * @param {string} url
   * @param {Record<string, string>} headers
   */
  get: async (url, headers = contentTypes.json) => await axiosParse('get', url, {}, headers),
  /**
   * @param {string} url
   * @param {any} data
   * @param {Record<string, string>} headers
   */
  post: async (url, data, headers = contentTypes.json) => await axiosParse('post', url, data, headers)
};

/**
 * @param {string} method
 * @param {string} url
 * @param {any} data
 * @param {Record<string, string>} headers
 * @returns {Promise<any>}
 */
const axiosParse = async (method, url, data, headers) => {
  try {
    const result = await axiosModule.request({ method, url, data, headers, maxContentLength: Infinity, maxBodyLength: Infinity, timeout: 4000 });
    return result.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { data } = error.response;
      const message = safeJsonParse(data.error) || data;
      if (isObject(message)) {
        throw new Error(JSON.stringify(message));
      }
      throw new Error(message);
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error(error.message);
    }
  }
};
