import React from "react";
import PropTypes from "prop-types";

require("./popup-block.scss");

const PopupBlock = ({
  className,
  BodyRender,
  bodyClassName,
  FooterRender,
  footerClassName,
  width, // string indicating width size, should be one of "xs", "sm", "md", "lg", "xl" and "xxl"
  height // string indicating height size, should be one of "xs", "sm", "md", "lg", "xl" and "xxl"
}) => {
  return (
    <div className={`popup-block ${className} width-${width} height-${height}`}>
      <div className={`body ${bodyClassName}`}>
        <div className="content">{BodyRender()}</div>
      </div>
      <div className={`footer ${footerClassName}`}>
        <div className="content">{FooterRender()}</div>
      </div>
    </div>
  );
};

PopupBlock.propTypes = {
  BodyRender: PropTypes.func,
  FooterRender: PropTypes.func,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

PopupBlock.defaultProps = {
  BodyRender: () => null,
  FooterRender: () => null,
  className: "",
  bodyClassName: "",
  footerClassName: "",
  width: "md",
  height: "md"
};

export default PopupBlock;
