import React from "react";
import PropTypes from "prop-types";

import "./popup-content-box.scss";

const PopupContentBox = ({ className, children }) => (
  <div className={`popup-content-box-container ${className}`}>
    <div className="content-container">{children}</div>
  </div>
);

PopupContentBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.instanceOf(Object)
};

PopupContentBox.defaultProps = {
  className: "",
  children: null
};

export default PopupContentBox;
