import React from "react";

export const PasswordForgetFooter = ({ onClick }) => (
  <div className="password-form-footer-inner">
    <div className="password-form-footer-buttons">
      <div className="button-wrapper">
        <button
          className="footer-button footer-button-large"
          onClick={onClick}
        >
          OKAY
        </button>
      </div>
    </div>
  </div>
);




