import React from "react";
import PropTypes from "prop-types";
import PopupContentBox from "../../components/PopupContentBox";
// import logoImage from "../../assets/images/TMlogo-withtext-white.svg";
import KovaaksLogo from '../../assets/images/logo.svg'
import Input from "../Input";

class PasswordForgetBody extends React.Component {
  render() {
    const {
      email,
      error,
      onChangeInput
    } = this.props;

    return (
      <div className="password-form-inner">
        <div className="password-form-header">
          <div className="header-content-wrapper">
            <div className="header-title">
              <span>Forgot Password</span>
            </div>
          </div>
          <div className="header-logo-wrapper">
            <img src={KovaaksLogo} alt="" className="header-logo" />
          </div>
        </div>
        <div className="password-form-header">
        <div className="header-subtitle">
          <span>Please enter your email below to reset your password</span>
        </div>
        </div>
        <div className="password-form-content">
          <PopupContentBox>
            <div className="password-form-content-main">
              <div className="input-group">
                {/* <div className="input-label">
                  <span>Email</span>
                </div> */}
                <div className="input-wrapper">
                  <Input
                    autoFocus
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Email"
                    onChange={onChangeInput}
                  />
                </div>
              </div>
              {error && (
                <div className="error-wrapper">
                  <span>{error}</span>
                </div>
              )}
            </div>
          </PopupContentBox>
        </div>
      </div>
    );
  }
}

PasswordForgetBody.propTypes = {
  email: PropTypes.string,
  error: PropTypes.node,
  onChangeInput: PropTypes.func
};

PasswordForgetBody.defaultProps = {
  email: "",
  error: null,
  onChangeInput: () => {}
};

export { PasswordForgetBody }
