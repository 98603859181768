import React from "react";

import PopupContentBox from "../../components/PopupContentBox";
import KovaaksLogo from '../../assets/images/logo.svg'

const SentEmailSuccessBody = () => (
  <div className="password-form-inner">
    <div className="password-form-header">
      <div className="header-content-wrapper">
        <div className="header-title">
          <span>Forgot Password</span>
        </div>
        <div className="header-subtitle">
          <span>Check your email!</span>
        </div>
      </div>
      <div className="header-logo-wrapper">
        <img src={KovaaksLogo} alt="" className="header-logo" />
      </div>
    </div>
    <div className="password-form-content">
      <PopupContentBox>
        <div className="password-form-content-main">
          <div className="password-form-text">
            If you've registered with that email, you will receive a link to reset your password.
          </div>
        </div>
      </PopupContentBox>
    </div>
  </div>
);

export default SentEmailSuccessBody;
