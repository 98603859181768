import React from "react";
import PopupBlock from "../../components/PopupBlock";
import { PasswordForgetBody } from "../../components/PasswordForget/PasswordForgetBody";
import { PasswordForgetFooter } from "../../components/PasswordForget/PasswordForgetFooter";
import { axios } from "../../utils/axios";

const beAuthDomain = process.env.REACT_APP_BE_AUTH_DOMAIN;

export default class PasswordForget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      error: ""
    };

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeInput(e) {
    const { value } = e.target;
    this.setState({
      email: value,
      error: ""
    });
  }

  onSubmit() {
    const { history } = this.props;
    const { email } = this.state;
    // eslint-disable-next-line no-useless-escape    
    const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      this.setState({
        error: "Input an email."
      });

      return false;
    }

    if (!reEmail.test(email)) {
      this.setState({
        error: "Invalid email."
      });

      return false;
    }


    axios.post(`${beAuthDomain}/ua_send_player_password_reset_email`, { username: email })
    .then(res => {
      console.log("Sent reset email! ", res);
      history.push("/sent-email");
    }).catch(err => {
      history.push("/sent-email");
      this.setState({
        error: "Failed to send a password reset email, please try again."
      });
    });
    /* firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(res => {
        // console.log("Sent reset email! ", res);
        history.push("/sent-email");
      })
      .catch(err => {
        // console.log("Error sending reset email: ", err);
        if (err.code === "auth/user-not-found") {
          history.push("/sent-email");
          // this.setState({
          //   error: "No user found for this email."
          // });
        } else {
          this.setState({
            error: "Failed to send a password reset email, please try again."
          });
        }
      }); */
    return true;
  }

  render() {
    const { email, error } = this.state;

    return (
      <div className="password-form">
        <PopupBlock
          width="xxl"
          height="auto"
          BodyRender={() => (
            <PasswordForgetBody
              email={email}
              error={error}
              onChangeInput={this.onChangeInput}
            />
          )}
          FooterRender={() => (
            <PasswordForgetFooter onClick={this.onSubmit} />
          )}
        />
      </div>
    );
  }
}
